<template>
    <div>
        <CSBreadcrumb/>
        <div class="filter-panel">
            <CSSelect
                    i-width="36rpx" style="width: 230px; margin-right: 0">
                <el-date-picker
                        type="datetime"
                        placeholder="请选择开始时间"
                        style="width: 214px"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        prefix-icon="el-icon-time"
                        v-model="buildingBookParams.startDate"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <span class="inline-gray"></span>
            <CSSelect
                    i-width="36rpx" style="width: 230px">
                <el-date-picker
                        type="datetime"
                        placeholder="请选择结束时间"
                        style="width: 214px"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        prefix-icon="el-icon-time"
                        v-model="buildingBookParams.endDate"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <button
                    class="btn btn-primary"
                    style="margin-left: 10px"
                    @click="queryPvByUser()"
            >
                查询
            </button>
        </div>


        <div  class="result-panel" style="padding-top: 0">
            <CSTable
                    :thead-top="filterHeight"
                    style="margin-top: 20px"
            >
                <template v-slot:header>
                    <div class="table-header-panel">
                        <div style="margin-top: 10px">PV访问量总计：：{{buildingBookParams.pvCount}}</div>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>访问时间</th>
                        <th>IP</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr :key="index" v-for="(pvItem,index) in queryPvByUserList">
                        <td>{{pvItem.createTime}}</td>
                        <td>{{pvItem.ip}}</td>
                    </tr>
                </template>

            </CSTable>
            <Pagination class="pagePV"/>
        </div>

    </div>
</template>

<script>
    import CSSelect from "@/components/common/CSSelect";
    import CSTable from "@/components/common/CSTable";
    import Pagination from "@/components/Pagination";
    import {queryPvByUserUrl} from "@/requestUrl";
    import CSBreadcrumb from "@/components/common/CSBreadcrumb";

    export default {
        name: "PV",
        components: {
            CSSelect,
            CSTable,
            Pagination,
            CSBreadcrumb
        },
        data() {
            return {
                filterHeight: 0,
                buildingBookParams: {},
                queryPvByUserList: []
            }
        },
        created() {
            // if (!this.$route.params.pvCount) {
            //     this.$router.push({
            //         name: "customerLeads"
            //     });
            //     return;
            // }
            this.$vc.on(this.$route.path, "pagination_page", "event", (page) => {
                this.queryPvByUser(page);
            });
            this.buildingBookParams = this.$route.params;

            this.queryPvByUser();
        },
        methods: {
            queryPvByUser(pageNo = 1, pageSize = 10) {
                const params = {
                    startDate: this.buildingBookParams.startDate,
                    endDate: this.buildingBookParams.endDate,
                    userId: this.buildingBookParams.userId,
                    pageSize,
                    pageNo,
                };
                this.$fly.post(queryPvByUserUrl, params).then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    if (pageNo === 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total,
                            pageSize,
                            currentPage: pageNo,
                        });
                    }
                    this.buildingBookParams.pvCount = res.data.total
                    this.queryPvByUserList = res.data.datas;
                });
            }
        }
    }
</script>

<style scoped>

</style>
